 import * as React from "react"
 import {useState, useEffect} from 'react';
 import axios from "axios";
 import "./layout.css"
 import Wrapper from "./Wrapper"
 
 
 const Intro = ({ children, data }) => {

  const [smallService, setSmallService] = useState([]);
  const [bigService, setBigService] = useState([]);
  const [service, setService] = useState([]);
  const headers = {
    'Authorization': process.env.GATSBY_DIRECTUS_TOKEN,
    'Content-Type': 'application/json'
  };
  const url = `${process.env.GATSBY_DIRECTUS_URL}/items/skiservice?sort=hierarchy`;
  useEffect(() => {
      axios({
          url: url,
          method: 'GET',
          headers: headers
      })
          .then(response => response.data)
          .then(data => setService(data.data))
          .catch(error => {
              console.log(error);
          });

          let big_service = [];
          service.map(n => big_service.push(n.big_service));
          setBigService(big_service);
  }, [service]);
 
   return (
     <>
       
       <Wrapper>
        <div className="grid grid-cols-12 gap-2 pb-12">
         <div className="md:col-span-7 col-span-12 md:p-0 md:pr-12 mx-auto">
          { children }
         </div>
         <div className="md:col-span-5 col-span-12 ml-6 md:ml-0">
             <h2 className=" xl:text-6xl text-3xl leading-tight font-semibold">{data.header}</h2>
             <p className="block  xl:text-xl leading-normal pt-4">{data.text}</p>
             <div className="grid grid-cols-2 border-yellow border-y-2">
                <div className="col-span-1 border-yellow border-r-2 m-4 leading-7">
                    <span className="font-medium">Servis lyží	</span><br />
                    {bigService[0]}
                </div>
                <div className="col-span-1 m-4">
                    <span className="font-medium">Servis snowboardu	 </span><br />
                    {bigService[1]}
                </div>
             </div>
         </div>
        </div>
       </Wrapper>
    
     </>
   )
 }
 
 export default Intro
 