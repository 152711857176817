import * as React from "react"
import {useState, useEffect} from 'react';
import axios from "axios";
import Wrapper from "./Wrapper"

const Service = () => {
    const [service, setService] = useState([]);
    const headers = {
      'Authorization': process.env.GATSBY_DIRECTUS_TOKEN,
      'Content-Type': 'application/json'
    };
    const url = `${process.env.GATSBY_DIRECTUS_URL}/items/skiservice?filter[status][_eq]=published&sort=hierarchy`;
    useEffect(() => {
        axios({
            url: url,
            method: 'GET',
            headers: headers
        })
            .then(response => response.data)
            .then(data => setService(data.data))
            .catch(error => {
                console.log(error);
            });
    }, []);

    return (
        <Wrapper>
        <div className="py-24">
            <table className="w-full table-auto">
                <tr className="h-28 text-lg bg-yellow">
                    <th className="box-border border-r border-white w-[287px]">Sortiment</th>
                    <th className="w-[181px] box-border border-x border-white text-center">Veľký servis</th>
                    <th className="w-[181px] box-border border-x border-white text-center">Malý servis</th>
                    <th className="w-[181px] box-border border-x border-white text-center">Brúsenie hrán</th>
                    <th className="w-[181px] box-border border-x border-white text-center">Voskovanie</th>
                    <th className="w-[181px] box-border border-x border-white text-center">Montáž viazania</th>
                    <th className="w-[181px] box-border border-l border-white text-center">Nastavenie viazania</th>
                </tr>
                {service ? service.map((value, index) => {
                    return(
                        <tr key={index} className="bg-white">
                            <td className="h-28 box-border border border-gray-200 pl-8"><div className="text-black text-lg font-medium">{value.assortment}</div></td>
                            <td className="h-28 box-border border border-gray-200 text-center text-black">{value.big_service}</td>
                            <td className="h-28 box-border border border-gray-200 text-center text-black">{value.small_service}</td>
                            <td className="h-28 box-border border border-gray-200 text-center text-black">{value.grinding_edge}</td>
                            <td className="h-28 box-border border border-gray-200 text-center text-black">{value.wax}</td>
                            <td className="h-28 box-border border border-gray-200 text-center text-black">{value.montage}</td>
                            <td className="h-28 box-border border border-gray-200 text-center text-black">{value.set_climbing}</td>
                        </tr>
                    )
                }) : null}
            </table>
        </div>
        </Wrapper>
    )
}

export default Service
