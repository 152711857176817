import * as React from "react"
import Seo from "../components/seo"
import Info from "../components/info";
import Layout from "../components/layout";
import HeroHeader from "../components/HeroHeader";
import SubheaderBig from "../components/SubheaderBigServis";
import SubheaderSmall from "../components/SubheaderSmallServis";
import Service from "../components/skiservice";
import Contact from "../components/Contact";
import Intro from "../components/Intro";
import { StaticImage } from "gatsby-plugin-image"



const LandingPage = () => {

    let subheader = {
        header: "Veľký servis",
        text: [ <div className="grid grid-cols-1">
                    <div className="cols-span-1">
                        <ul className="list-disc pl-4 pt-6">
                            <li>Zalievanie sklznice</li>
                            <li>Brúsenie sklznice kameňom</li>
                            <li>Bočné brúsenie hrán</li>
                            <li>Jemné dobrúsenie hrán - tuning</li>
                            <li>Voskovanie a leštenie</li>
                        </ul>
                    </div>
                </div>
            ], 
        }

    let subheader2 = {
        header: "Malý servis",
        text: [ <div className="grid grid-cols-1">
                <div className="cols-span-2">
                    <ul className="list-disc pl-4 pt-6">
                        <li>Brúsenie sklznice kameňom</li>
                        <li>Bočné brúsenie hrán</li>
                        <li>Jemné dobrúsenie hrán - tuning</li>
                        <li>Voskovanie a leštenie</li>
                    </ul>
                </div>
            </div>
            ],
        }



    return (
        <div>
            <Layout>
            <HeroHeader title="Skiservis pre lyže">
                    <StaticImage placeholder="blurred" className="h-[400px]" objectFit="cover" objectPosition="50% 50%" height="400"  imgClassName="object-cover" src="../../static/images/headers/hero-header5.png" alt="skiers" />
                </HeroHeader>
                <SubheaderBig data={subheader}>
                    <div className="hidden md:block"><StaticImage placeholder="blurred" imgClassName="" src="../../static/images/subheaders/subheader04.png" alt="skiers" /></div>
                    <div className="md:hidden block"><StaticImage placeholder="blurred"  imgClassName="" src="../../static/images/subheaders/subheader04-small.png" alt="skiers" /></div>
                </SubheaderBig>
                <hr className="border-yellow  my-12 mx-16" />
                <SubheaderSmall data={subheader2}>
                    <div className="hidden md:block"><StaticImage placeholder="blurred" imgClassName="" src="../../static/images/subheaders/subheader05.png" alt="skiers" /></div>
                    <div className="md:hidden block"><StaticImage placeholder="blurred"  imgClassName="" src="../../static/images/subheaders/subheader05-small.png" alt="skiers" /></div>
                </SubheaderSmall>
                <Service />
                <Contact></Contact>
            </Layout>
        </div>
    )
}

export const Head = () => <Seo title="Home" />

export default LandingPage